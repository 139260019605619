// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-gracias-js": () => import("./../../../src/pages/gracias.js" /* webpackChunkName: "component---src-pages-gracias-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metodo-js": () => import("./../../../src/pages/metodo.js" /* webpackChunkName: "component---src-pages-metodo-js" */),
  "component---src-pages-nosotros-js": () => import("./../../../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politica-js": () => import("./../../../src/pages/politica.js" /* webpackChunkName: "component---src-pages-politica-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-terminos-js": () => import("./../../../src/pages/terminos.js" /* webpackChunkName: "component---src-pages-terminos-js" */),
  "component---src-templates-producto-js": () => import("./../../../src/templates/producto.js" /* webpackChunkName: "component---src-templates-producto-js" */)
}

